import {CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {TokenStorageService} from "./token-storage.service";

@Injectable()
export class NeedAuthGuard implements CanActivate {

  constructor(private token: TokenStorageService, private router: Router) {
  }
  roles: string[] = [];
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {       
    const expectedRole = route.data.expectedRole;
    const redirectUrl = route['_routerState']['url'];
 
    this.roles = this.token.getAuthorities();
    var returnVal = false;
    if (this.token.getToken() && this.roles[0] ==  expectedRole ) {       
      return true ;   
    }


      this.router.navigateByUrl(   
        this.router.createUrlTree(
          ['/auth/login'], {
            queryParams: {
              redirectUrl
            }
          }
        )
      );
          
     return false ;
   

  
  }

 
}
