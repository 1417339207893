import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { navItems } from '../../_nav';
import { NotifierService } from 'angular-notifier';
import { TokenStorageService } from '../../services/token-storage.service';
import { Router } from '@angular/router';
import { InspectorService } from '../../services/inspector.service';
import { NotificationService } from '../../services/notification.services';
import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css'],
  providers: [InspectorService, TokenStorageService, NotificationService]
})
export class DefaultLayoutComponent implements OnInit {
  private readonly notifier: NotifierService;
  title = 'toaster';
  public sidebarMinimized = false;
  public navItems = navItems;
  public page: any;

  constructor(public inspectorservice: InspectorService, notifierService: NotifierService,
    private notificationService: NotificationService, private token: TokenStorageService, public http: HttpClient,
    private _cdr: ChangeDetectorRef, public router: Router) {
    this.notifier = notifierService;
  }

  makeHttpCall() {
    this.http.get('https://jsonplaceholder.typicode.com/comments')
      .subscribe((r) => {
        
      });
  }
  url = environment.baseUrl;
  currentYear: number = new Date().getFullYear();
  inspectorPage;
  opp;
  userName;
  AllNotif;
  countNotif: number = 0;

  ngOnInit(): void {
    if (this.token.getToken()) {
      this.userName = this.token.getUsername();
    }
    this.getinspector();
    this.get();
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    this.token.signOut();
    this.router.navigate(['/auth/login']);
  }
  profil() {
    this.router.navigate(['/profil']);
  }

  notify(type, message) {
    this.notifier.notify(type, message); // call notification
  }

  clickNotif(c: any, opp: any) {
    if (c.readed == false) {
      this.countNotif = this.countNotif - 1;
      this._cdr.detectChanges();
    }
    if (c.operator != null) {
      this.router.navigate(['/operator/details/', c.operator.id]);
      c.readed = true;
      this.notificationService.update(c).subscribe(data => {
      })
    } else {
      c.readed = true;
      this.notificationService.update(c).subscribe(data => {
      })
    }
  }
  id: number;

  delete() {
    swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert Notifications!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.notificationService.gets().subscribe(data => {
          this.notif = data;
         
          for (let not of this.notif) {
            this.notificationService.delete(not.id).subscribe(data => {
            })
          }
        })
      }
    });
  }
  notif: any;

  getinspector() {
    this.countNotif = 0;
    // this.inspectorservice.getInspectors()
    this.inspectorservice.getInspector(1)
      .subscribe(data => {
        this.inspectorPage = data;
        this.inspectorPage.notification = this.inspectorPage.notification.reverse()
        this.opp = this.inspectorPage;
        this.opp.notification.sort(function (a, b) {
          return - (a.id - b.id);
        });

        this.AllNotif = this.opp.notification;
        for (let noti of this.AllNotif) {
          if (noti.readed == false) {
            this.countNotif = this.countNotif + 1;
          }
        }
      })
  }
  admin;
  get() {
    this.inspectorservice.getInspector(1).subscribe(data => {
      this.page = data;
      //  this.page = this.page.filter(item => item.username === this.userName);
      this.admin = this.page;
    });
  }

  clearAllSessions() {
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

}



