import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  {
    name: 'Inspector',
    url: '/inspector',
    icon: 'icon-note',

  },
  {
    name: 'Operator',
    url: '/operator',
    icon: 'icon-people',

  },
 
  {
    name: 'Inspection',
    url: '/inspection',
    icon: 'icon-layers',

  },
  {
    name: 'Review',
    url: '/reviews',
    icon: 'icon-info',

  },
  {
    name: 'Settings',
    url: '/setting',
    icon: 'icon-wrench',

  },
];
