import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Inspector } from '../model/inspector.model';
import { environment } from '../../environments/environment';

@Injectable()

export class InspectorService {
  constructor(public http: HttpClient) { }

  url = environment.baseUrl

  getInspectors() {
    return this.http.get(this.url + '/api/auth/user/')
      .pipe(map(data => data))
  }

  getInspectors2() {
    return this.http.get(this.url + '/api/auth/usersData/')
      .pipe(map(data => data))
  }

  getInspector(id: number) {
    return this.http.get(this.url + '/api/auth/user/' + id)
      .pipe(map(data => data))
  }

  save(inspector) {
    return this.http.post<Inspector>(this.url + '/api/auth/user/', inspector)
      .pipe(map(data => data))
  }

  update(info: any) {
    return this.http.put<Inspector>(this.url + '/api/auth/user/' + info.id, info)
      .pipe(map(data => data))
  }

  deleteInspector(id: number) {
    return this.http.delete(this.url + '/api/auth/user/' + id)
      .pipe(map(data => data))
  }

  updatePassword(info: any) {
    return this.http.put<Inspector>(this.url + '/api/auth/updatePassword/' + info.id, info)
      .pipe(map(data => data))
  }

  getByUserName(username) {
    return this.http.get(this.url + '/api/auth/username?username=' + username)
      .pipe(map(data => data))
  }

  getCountUsers(year) {
    return this.http.get(this.url + '/api/auth/countUsers?year=' + year)
      .pipe(map(data => data))
  }
 
}