import { INavData } from '@coreui/angular';

export const navItems2: INavData[] = [
    {
       /*  title: true,
        name: 'Theme' */
        
      },
 
];
