import { Injectable} from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Notification } from '../model/notification.model';

import { environment } from '../../environments/environment';

@Injectable()

export class NotificationService {
    constructor(public http:HttpClient){}

  url = environment.baseUrl;

  gets() {
       return this.http.get(this.url+'/notification')
      .pipe(map(data => data))
      
    }

 

  get(id:number) {
      return this.http.get(this.url+'/notification/'+id)
      .pipe(map(data => data))
      
    }
 
  save(sv) {
 
    return this.http.post<Notification>(this.url+'/notification',sv)
    .pipe(map(data => data))
  }

  

  update(notification: Notification) {
 
    return this.http.put<Notification>(this.url+'/notification/'+notification.id,notification)
    .pipe(map(data => data))
  }


  delete(id: number ) {
 
    return this.http.delete(this.url+'/notification/'+id)
    .pipe(map(data => data))
  }

 

} 