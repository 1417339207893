import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { navItems2 } from '../../_nav2';
import { NotifierService } from 'angular-notifier';
import { TokenStorageService } from '../../services/token-storage.service';
import { InspectorService } from '../../services/inspector.service';
import { NotificationService } from '../../services/notification.services';
import { Router } from '@angular/router';
import { Notification } from '../../model/notification.model';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { InspectionService } from '../../services/inspection.service';
import swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css'],
  providers: [InspectorService, InspectionService, NotificationService]

})
export class DefaultLayout2Component implements OnInit {
  private readonly notifier: NotifierService;
  title = 'toaster';
  public sidebarMinimized = false;
  public navItems2 = navItems2;
  userName: string;
  inspectorPage: any = [];
  inspectorPage1: any = [];
  editMode1: any;
  editMode: any;
  editMode3: any;
  opp: any;
  dataSource: any;
  allInspections;
  url = environment.baseUrl;
  constructor(private httpClient: HttpClient, private notificationService: NotificationService,
    notifierService: NotifierService, private _cdr: ChangeDetectorRef,
    private token: TokenStorageService, public inspectorservice: InspectorService, public router: Router
    , public inspectionservice: InspectionService) {
    this.notifier = notifierService;
  }
  currentYear: number = new Date().getFullYear();
  countNotif: number = 0;
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  date: Date = new Date();
  dateToexec
  ngOnInit(): void {
    if (this.token.getToken()) {
      this.userName = this.token.getUsername();
    }
    this.getinspector();
  }

  logout() {
    this.token.signOut();
    this.router.navigate(['/auth/login']);
  }

  notify(type, message) {
    this.notifier.notify(type, message); // call notification
  }

  ExecDate;
  notif: Notification;
  showNotif() {
    for (let inspec of this.inspectorPage[0].inspection) {
      var inspDate
      if (inspec.inspectionPlanPs2 != null && inspec.inspectionPlanPs2.length != 0) {
        inspDate = inspec.inspectionPlanPs2[inspec.inspectionPlanPs2.length - 1];
      } else {
        inspDate = inspec.inspectionPlan;
      }
      this.dateToexec = new Date(inspDate);
      var mont = this.dateToexec.getMonth() + 2;
      var year = this.dateToexec.getFullYear();
      var diff;
      if (mont > 12) {
        diff = mont - 12;
        mont = diff;
        year = this.dateToexec.getFullYear() + 1
      }
      var operat
      var dateInspectionPlan = new Date(year + "/" + mont + "/" + 15)
      var dateNow = new Date();
      var Difference_In_Time = dateInspectionPlan.getTime() - dateNow.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      if (Difference_In_Days < 15 && Difference_In_Days > 0) {
        for (let op of this.inspectorPage[0].operator) {
          for (let inspOfOp of op.inspection) {/*  */
            if (inspec.id == inspOfOp.id) {
              operat = op;
            }
          }
        }
        if (operat) {
          this.notify('info', 'Your inspection for operator ' + operat.name + ' will finish in '
            + Math.round(Difference_In_Days) + " Days. Please execut it.");
          this.notif = new Notification();
          this.notif.readed = false;
          this.notif.notif = 'Your inspection for operator ' + operat.name + ' will finish in '
            + Math.round(Difference_In_Days) + " Days. Please Please execut it."
          this.notif.operator = operat;
       
        this.notificationService.save(this.notif).subscribe(res => {
          this.inspectorPage[0].notification.push(res);
          this.inspectorservice.update(this.inspectorPage[0]);
        }) }
      }

    }
  }


  clickNotif(c: any, opp: any) {
    if (c.readed == false) {
      this.countNotif = this.countNotif - 1;
      this._cdr.detectChanges();
    }
    if (c.operator != null) {
      this.router.navigate(['/auditorspace/details2/', c.operator.id]);
    }
    c.readed = true;
    this.notificationService.update(c).subscribe(data => {

    })
  }
  AllNotif: any;
  allInspectors;
  getinspector() {
    this.inspectorservice.getInspectors()
      .subscribe(data => {
        this.inspectorPage = data;
        this.allInspectors = data;
        this.inspectorPage = this.inspectorPage.filter(item => item.username === this.userName);
        this.allInspectors = this.allInspectors.filter(item => item.roles[0].name === "ROLE_ADMIN");
        this.inspectorPage[0].notification = this.inspectorPage[0].notification.reverse();
        this.opp = this.inspectorPage[0];
        this.dataSource = this.inspectorPage[0].operator;
        this.opp.notification.sort(function (a, b) {
          return - (a.id - b.id);
        });
        this.AllNotif = this.opp.notification;
        for (let noti of this.AllNotif) {
          if (noti.readed == false) {
            this.countNotif = this.countNotif + 1;
          }
        }
        this.showNotif();
      })
  }

  notification: Notification = new Notification()
  update(c: any) {
    this.notification.readed = false;
    this.notification.notif = this.inspectorPage[0].name + " updated his personnel information userName/Email";
    this.notificationService.save(this.notification).subscribe(resp => {
      this.allInspectors[0].notification.push(resp)
      this.inspectorservice.update(this.allInspectors[0]).subscribe(response => {
        this.inspectorservice.update(c).subscribe(data => {
        })
      })
    })
  }

  selectedFile: File;
  public onFileChanged(event) {
    //Select File
    this.selectedFile = event.target.files[0];
    const fileName = event.target.files[0].name;
  }

  onUpload() {
    if (this.selectedFile.type.match(/image\/*/) == null) {
      swal.fire(
        'Failed',
        'Only images can be uploaded.',
        'error'
      )
    } else {
      var splitFile = this.selectedFile.name.split('.');
      this.date = new Date();
      var name = splitFile[splitFile.length - 2] + this.date.getTime() + '.' + splitFile[splitFile.length - 1];
      const uploadImageData = new FormData();
      uploadImageData.append('imageFile', this.selectedFile, name);
      this.httpClient.post(this.url + '/image/upload', uploadImageData, {
        reportProgress: true,
        responseType: 'text'
      })
        .subscribe((response) => {
          this.inspectorPage[0].imageName = name;
          this.inspectorservice.update(this.inspectorPage[0]).subscribe(data => {
            Swal.fire(
              'Updated!',
              'Your Image has been updated.',
              'success'
            )
            this.ngOnInit()
          })
        })
    }
  }

}
