import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../services/auth.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { AuthLoginInfo } from '../../services/login-info';
import { Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  private loginInfo: AuthLoginInfo;
  private authority: string;
  constructor(private router : Router ,private authService: AuthService, private tokenStorage: TokenStorageService) { }

  ngOnInit() {
  /*  if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.router.navigateByUrl('/dashboard');
      this.roles = this.tokenStorage.getAuthorities();
      
    }*/

    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getAuthorities();
      this.roles.every(role => {
        if (role === 'ROLE_ADMIN') {          
          this.router.navigateByUrl('/dashboard');        
        } else if (role === 'ROLE_USER') {       
          this.router.navigateByUrl('/auditorspace');         
        }                   
      });
    }
  }

  onSubmit() {  

    this.loginInfo = new AuthLoginInfo(
      this.form.username,
      this.form.password);

    this.authService.attemptAuth(this.loginInfo).subscribe(
      data => {
       
        this.tokenStorage.saveToken(data.accessToken);
        this.tokenStorage.saveUsername(data.username);
        this.tokenStorage.saveAuthorities(data.authorities);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
       
        if (this.tokenStorage.getToken()) {
          this.roles = this.tokenStorage.getAuthorities();
          this.roles.every(role => {
            if (role === 'ROLE_ADMIN') {
              this.authority = 'admin';
              this.router.navigateByUrl('/dashboard');
              return false;
            } else if (role === 'ROLE_USER') {
              this.authority = 'user';
              this.router.navigateByUrl('/auditorspace');
              return false;
            }           
            
          });
        }


        
        this.roles = this.tokenStorage.getAuthorities();

      //  this.reloadPage();
      },
      error => {
        
        this.errorMessage = error.error.message;
        this.isLoginFailed = true;
      }
    );
  }

  /*reloadPage() {
    window.location.reload();
  }*/
}
