import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Import Containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { DefaultLayout2Component } from './containers2';
import { NeedAuthGuard } from './services/NeedAuthGuard.service';


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },

  {
    path: 'auth/login',
    component: LoginComponent
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Dashboard',
      expectedRole: 'ROLE_ADMIN'
    },
    children: [

      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'inspector',
        loadChildren: () => import('./views/inspector/inspector.module').then(m => m.InspectorModule)
      },
      {
        path: 'operator',

        loadChildren: () => import('./views/operator/operator.module').then(m => m.OperatorModule)

      },
      {
        path: 'inspection',
        loadChildren: () => import('./views/inspection/inspection.module').then(m => m.InspectionModule)
      },
      {
        path: 'setting',
        loadChildren: () => import('./views/parametrage/parametrage.module').then(m => m.ParametrageModule)
      },
      {
        path: 'profil',
        loadChildren: () => import('./views/adminprofil/adminprofil.module').then(m => m.AdminprofilModule)
      },
      {
        path: 'reviews',
        loadChildren: () => import('./views/reviews/reviews.module').then(m => m.ReviewsModule)
      },

    ], canActivate: [NeedAuthGuard]
  },

  {
    path: '',
    component: DefaultLayout2Component,

    children: [
      {
        path: 'auditorspace',
        loadChildren: () => import('./views/auditorspace/auditorspace.module').then(m => m.AuditorspaceModule)
      },

    ],
    canActivate: [NeedAuthGuard],
    data: {
      title: '',
      expectedRole: 'ROLE_USER'
    },
  },

  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }







