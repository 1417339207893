import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Inspection } from '../model/inspection.model';

import { environment } from '../../environments/environment';

@Injectable()

export class InspectionService {
    constructor(public http:HttpClient){}

    url = environment.baseUrl

    gets() {
       return this.http.get(this.url+'/inspection')
      .pipe(map(data => data))  
    }

    get(id:number) {
      return this.http.get(this.url+'/inspection/'+id)
      .pipe(map(data => data))
    }
 
    save(inspection: Inspection) {
      return this.http.post(this.url+'/inspection',inspection)
      .pipe(map(data => data))
    }
   

    update(inspection: Inspection) {
      return this.http.put(this.url+'/inspection/'+inspection.id,inspection)
     .pipe(map(data => data))
    }

    delete(id: number ) {
    return this.http.delete(this.url+'/inspection/'+id)
    .pipe(map(data => data))
    }

 

} 